import React from 'react';
import {  Box, Card, Center, CircularProgress, CircularProgressLabel, Flex, Image, Text, VStack} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {  TimeIcon, StarIcon } from '@chakra-ui/icons'

type Props = {
  courseName?: string
  moduleName?: string
  imageUrl: string
  progressValue?: number
  rating?: string
  hours?: number
  reviewersCount?: number
}

export default function RatingCard({courseName, moduleName, imageUrl, progressValue, rating, hours, reviewersCount}: Props) {
  return (
      <Card 
        as={ motion.div } 
        initial={{ opacity:0 }}
        whileInView={{ opacity: 1 }}
        transition=' 0.5s easeOut' 
        maxW={[ 'md','full' ]} 
        h={{sm:'80vh',md:'60vh', lg:'40vh'}}
        mt={5}
        ml={5}
        mr={5}
        bgGradient='linear(to-r, teal.500, green.500)'
        >
            <Flex flexDirection={'row'}>
                <Center flex='1' h={'40vh'}>
                    <Image
                        src={imageUrl}
                        alt='Green double couch with wooden legs'
                        borderRadius='lg'
                        w={200}
                    />
                </Center>
                <Box flex='2' h={'40vh'}>
                    <VStack align='stretch'>
                        <Box h='10vh'  p={1}>
                            <Flex justifyContent={'space-between'} pt={5}>
                                <Box flex='10' >
                                    <Text fontSize={'1xl'} color={"#36372B"}>{moduleName}</Text>
                                </Box>
                                <Box flex='0.2'>
                                    <TimeIcon  w={5} h={5} color={"#36372B"}/>
                                </Box>
                                <Box flex='1' pl={1}>
                                    <Text>{hours} Hours</Text>
                                </Box>
                            </Flex>
                             
                        </Box>
                        <Box h='20vh' >
                        <Flex justifyContent={'space-between'}>
                                <Box flex='2' pl={2}>
                                    <Text fontSize={{sm:'2xl',md:'3xl', lg:'4xl'}}>{courseName}</Text>
                                </Box>
                                <Center flex='1' justifyContent={'center'}>
                                    {/* @ts-ignore */}
                                    <CircularProgress value={progressValue && progressValue.toFixed(2)} color='#D18A36' thickness='10px' size='90px'>
                                     {/* @ts-ignore */}
                                     <CircularProgressLabel>{progressValue && progressValue.toFixed(2)}%</CircularProgressLabel>
                                    </CircularProgress>
                                </Center>
                                
                        </Flex>
                        </Box>
                        <Box h='5vh'>
                            <Flex>
                                <Box  flex='1'>
                                    {
                                        Array.from(Array(Math.floor(Number(rating))).keys()).map(rate => (
                                            <StarIcon key={rate}  w={5} h={5} color={"#D0D540"}/>
                                        ))
                                    }
                                </Box>
                                <Box  flex='1'>
                                    {rating} Rating Score
                                </Box>
                                <Box  flex='5'>
                                    {reviewersCount} Reviewers
                                </Box>
                            </Flex>
                            
                        </Box>
                    </VStack>
                </Box>
            </Flex>
      </Card>
  )
}