import React, { useState, useEffect} from 'react';
import { Box } from '@chakra-ui/react';
import RatingCard from './RatingCard';
import ReactPaginate from 'react-paginate';
import './Dashboard.css';
import axios from 'axios';

export type Feedback = {
  averageDifficulty: number,
  courseName: string,
  hours: number
  lmsName: string
  logo: string
  totalFeedbacks: number,
  totalScore: number
}

export default function Dashboard() {
  const [feedBacks, setFeedBacks] = useState<Feedback[] | null >();

  const fetchData = async () => {
    await axios
    .get("https://us-central1-thefeedbackcounter-5a38b.cloudfunctions.net/v1/dashboard-home")
    .then(function (response) {
      setFeedBacks(response.data.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box mt={20}>
        {
          feedBacks && feedBacks.map(feedback => (
            <RatingCard 
            key={feedback.courseName} 
            courseName={feedback.courseName} 
            moduleName={feedback.lmsName} 
            imageUrl={feedback.logo}
            progressValue={(feedback.averageDifficulty / 5)*100} 
            rating={feedback.totalScore.toFixed(1)} 
            hours={feedback.hours} 
            reviewersCount={feedback.totalFeedbacks}/>
          ))
        }
          <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={()=>{}}
          pageRangeDisplayed={5}
          pageCount={10}
          previousLabel="<<"
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          renderOnZeroPageCount={null|| undefined}
        />
      </Box>
    </>
  )
}
