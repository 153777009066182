import React from 'react';
import './Navbar.css';
import {InputGroup, Input, InputRightElement, Text} from '@chakra-ui/react';
import { SearchIcon  } from '@chakra-ui/icons'

export default function Navbar() {
  return (
    <div className='header'>
        <nav className='navbar'>
            
            <Text color={'#ffffff'} fontSize='2xl' isTruncated w={'400px'}>The Feedbacks Counter</Text>
            <InputGroup size='md' pl={{sm:20,md:200, lg:100}} pr={{sm:20,md:200, lg:300}}>
                <Input
                    pr='4.5rem'
                    type={'text'}
                    placeholder='Search the Course'
                    focusBorderColor='#1A5158'
                    color={'#ffffff'}
                />
                <InputRightElement width='40rem'>
                    <SearchIcon  w={5} h={5} color={{sm:"transparent",md:"#767983", lg:"#767983"}}/>
                </InputRightElement>
            </InputGroup>
        </nav>
    </div>
  )
}